@font-face {
  font-family: "Cofo Sans";
  src: url("fonts/CoFoSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "Cofo Sans";
  src: url("fonts/CoFoSans-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Cofo Sans";
  src: url("fonts/CoFoSans-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Cofo Sans";
  src: url("fonts/CoFoSans-Medium.woff2") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Cofo Sans";
  src: url("fonts/CoFoSans-Bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Cofo Sans";
  src: url("fonts/CoFoSans-Bold.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Cofo Sans";
  src: url("fonts/CoFoSans-Italic.woff") format("woff");
  font-style: italic;
}

@font-face {
  font-family: "Cofo Sans";
  src: url("fonts/CoFoSans-Italic.woff2") format("woff2");
  font-style: italic;
}

@font-face {
  font-family: "Cofo Sans Mono";
  src: url("fonts/CoFoSansMono-Regular.woff") format("woff");
}

@font-face {
  font-family: "Cofo Sans Mono";
  src: url("fonts/CoFoSansMono-Regular.woff2") format("woff2");
}
